@import 'libs/design-system/src/styles.scss';

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: calc(100% + 37px);
  position: absolute;
  border: 0;
}
